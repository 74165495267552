@charset 'utf-8';

@import 'settings';
@import 'foundation';
@import 'motion-ui';

// Global styles
@include foundation-global-styles;
@include foundation-forms;
@include foundation-typography;

// Grids (choose one)
// @include foundation-xy-grid-classes;
// @include foundation-grid;
@include foundation-flex-grid;

// Generic components
@include foundation-button;
@include foundation-button-group;
@include foundation-close-button;
@include foundation-label;
@include foundation-progress-bar;
@include foundation-slider;
@include foundation-range-input;
@include foundation-switch;
@include foundation-table;
// Basic components
@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-callout;
@include foundation-card;
@include foundation-dropdown;
@include foundation-pagination;
@include foundation-tooltip;

// Containers
@include foundation-accordion;
@include foundation-media-object;
@include foundation-orbit;
@include foundation-responsive-embed;
@include foundation-tabs;
@include foundation-thumbnail;
// Menu-based containers
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-accordion-menu;
@include foundation-drilldown-menu;
@include foundation-dropdown-menu;

// Layout components
@include foundation-off-canvas;
@include foundation-reveal;
@include foundation-sticky;
@include foundation-title-bar;
@include foundation-top-bar;

// Helpers
@include foundation-float-classes;
@include foundation-flex-classes;
@include foundation-visibility-classes;
// @include foundation-prototype-classes;

// Motion UI
@include motion-ui-transitions;
@include motion-ui-animations;

@mixin hero-section ($hero-height, $hero-text-size) {
    .hero-section {
      background: url('../img/ARBEldeeEaster2014_079@1x-crop.jpg') 50% no-repeat;
      background-size: cover;
      height: $hero-height;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;

      .hero-section-text {
        color: $white;
        text-shadow: 1px 1px 2px $black;
        padding-bottom: 35%;

        h1 {
            font-weight: 700;
            font-size: $hero-text-size;
        }
      }
    }
}

@mixin festival-hero-section ($hero-height, $hero-text-size) {
    .festival-hero-section {
      background: 
      linear-gradient(
        rgba(66, 66, 66, 0.55),
        rgba(0, 0, 0, 0.5),
        rgba(0, 0, 0, 0.1)
      ),
      url('../img/ARBEldeeEaster2014_079@1x-crop.jpg') 50% no-repeat;
      background-size: cover;
  
      height: $hero-height;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;

      .festival-hero-section-text {
        color: $white;
        text-shadow: 1px 1px 2px $black;
        padding-top: 0%;

        h1 {
            font-weight: 700;
            font-size: 1.4 * $hero-text-size;
        }
        h4 {
            font-weight: 700;
            font-size: $hero-text-size;
        }
      }
    }

}


.center {
    text-align: center;
}

// .centre-menu > ul {
// }

// .centre-menu > ul > li {
//     height: 120px;
// }

// .centre-menu > ul > li > a {
//     line-height: 82px;
//     text-align: center;
//     white-space: nowrap;
//     font-weight: 700;
// }


$hero-height: 60vh;
$hero-text-size: 3vw;

@media screen and (max-width: 39.9375em) {
    @include hero-section(300px, 0.75em);
    @include festival-hero-section(300px, 1.1em);

    #top_logo {
        height: 160px;
        padding-bottom: 15px;
    }

    // .top-bar {
    //     background-color: rgb(230,230,230);

    //     .menu {
    //         background-color: rgb(230,230,230);
    //     }
    // }

    // .menu-container {
    //     padding: 0px 0px;
    // }

    #title_bar {
        padding: 15px 0px 0px 0px;
        margin-bottom: 15px;
    } 

    // .card-image {
    //     height: 150px;
    //     overflow: hidden;
    // }

    .card-divider {
        h5 {
            font-size: smaller;
        }
    }

    .card-column {
        padding: 10px 5px 0px 5px;
    }

    .booking-button {
        width: 100%;
    }

}

@media screen and (min-width: 40em) {
    @include hero-section(550px, 2.0em);
    @include festival-hero-section(550px, 3.0em);
    #top_logo {
        height: 120px;
        margin: 20px 0px 20px 0px;
    }

    #title_bar {
        //padding-top: 20px;
        margin-bottom: 0px;
    }

    // .menu-container {
    //   padding-top: 50px;
    // }

    // .card-image {
    //     height: 190px;
    //     overflow: hidden;
    // }

    .card-divider {
        h5 {
            font-size: large;
        }
    }

    .card-column {
        padding: 20px 5px 5px 5px;
    }

    .centre-menu > ul > li {
      height: 120px;
    }

    .left-margin {
      margin-left: 15px;
    }

    .centre-menu > ul > li {
      height: 120px;
    }

}

/* Medium only */
@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .menu-container {
        padding: 10px 0px;
  }

  // #example-menu {
  //   padding-left: 4vw;
  // }

  .card-divider {
    h5 {
        font-size: 0.9rem;
    }
  }
}

.card-divider {
    h5 {
        font-weight: 700;
        text-align: center;
    }
}

#body-text {
    padding-top: 10px;
}

#festival-body-text {
    padding-top: 25px;
}

.pricing-table {
  background-color: $white;
  border: solid 1px $medium-gray;
  width: 100%;
  text-align: center;
  list-style-type: none;
  margin-left: 0px;

  li {
    border-bottom: dotted 1px $medium-gray;
    padding: 0.875rem 1.125rem;

    &:last-child {
      border-bottom: 0;
    }
  }

  .title {
    background-color: $black;
    color: $white;
    border-bottom: 0;
  }

  .price {
    background-color: $light-gray;
    font-size: 2rem;
    border-bottom: 0;
  }

  .description {
    color: $dark-gray;
    font-size: $small-font-size;
  }

  :last-child {
    margin-bottom: 0;
  }
}

.bold-text {
  font-weight: 700;
}

.italic-text {
  font-style: italic;
}

.top-margin {
  margin-top: 15px;
}

.text-left {
  text-align: left;
}

.text-centre {
  text-align: center;
}

.text-right {
  text-align: right;
}

.tabs-title {
  text-align: center;
}

#example-menu {
  min-height: 0px;
}

.card-divider {
  background-color: rgba(240, 138, 30, 0.75);
}

.logo-box {
  img {
    width: 23%;
  }
}

.social-box {
  img {
    width: 23%;
  }
}

#festival-menu {
  text-align: center;
}

#arb-logo {
  max-height: 250px;
  margin-bottom: 0.5rem;
}

.present-logos {
  max-height: 250px;
  display: block;
  margin: auto;
}

.home-text {
    line-height: 2;
}

#clockdiv{
	font-family: sans-serif;
	color: #fff;
	display: inline-block;
	font-weight: 600;
	text-align: center;
  font-size: 30px;
  padding-top: 20px;
  padding-bottom: 20px;
}

#clockdiv > div{
	padding: 10px;
	border-radius: 3px;
	//background: rgba(107, 106, 106, 0.5);
  display: inline-block;
}

#clockdiv div > span{
	padding: 10px;
	border-radius: 3px;
	//background: rgba(107, 106, 106, 0.5);
  display: inline-block;
  font-size: 45px;
}

.smalltext{
	padding-top: 5px;
	font-size: 30px;
}

@media screen and (max-width: 39.9375em) {
  .smalltext{
    padding-top: 5px;
    font-size: 12px;
  }
  #clockdiv div > span{
    padding: 5px;
    border-radius: 3px;
    //background: rgba(107, 106, 106, 0.5);
    display: inline-block;
    font-size: 40px;
  }
  #clockdiv > div{
    padding: 5px;
    border-radius: 3px;
    //background: rgba(107, 106, 106, 0.5);
    display: inline-block;
  }

  #clockdiv{
    padding-top: 5px;
    padding-bottom: 5px;
  }
  
}

.select-centre {
  text-align-last: center;
}

.loader {
  width: 12px;
  height: 12px;
  border: 5px solid #e27b20;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  margin-left: 10px;
  }

  @keyframes rotation {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
  } 

.voucherDetails {
  margin-bottom: 0;
}

.userCode {
  position: absolute; left: -5000px;
}